var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('el-form',{attrs:{"inline":true}},[_c('el-form-item',{attrs:{"label":"发起时间"}},[_c('el-date-picker',{attrs:{"type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期","value-format":"yyyy-MM-dd","clearable":false},model:{value:(_vm.timeList),callback:function ($$v) {_vm.timeList=$$v},expression:"timeList"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getList(1)}}},[_vm._v("查询")])],1)],1),_c('auto-table',{attrs:{"DataList":_vm.DataList,"option":_vm.Option,"total":_vm.Total},on:{"changePage":_vm.getList},scopedSlots:_vm._u([{key:"createTime",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(new Date(row.signFlowStartTime).Format("yyyy-MM-dd hh:mm:ss"))+" ")])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(_vm.statusMap[row.signFlowStatus])+" ")])]}},{key:"signers",fn:function(ref){
var row = ref.row;
return [_c('div',_vm._l((row.signers),function(sign,i){return _c('div',{key:i},[(sign.psnSigner)?_c('p',[_vm._v("个人："+_vm._s(sign.psnSigner.psnAccount.accountMobile))]):_vm._e(),(sign.orgSigner)?_c('p',[_vm._v("企业："+_vm._s(sign.orgSigner.orgName))]):_vm._e()])}),0)]}},{key:"handler",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.getUserUrl(row)}}},[_vm._v("签署链接")]),_c('el-button',{attrs:{"size":"small","type":"success"},on:{"click":function($event){return _vm.getfileUrl(row)}}},[_vm._v("查看合同")])],1)]}}])}),_c('el-dialog',{attrs:{"title":"用户签署链接","visible":_vm.urlShow,"width":"30%"},on:{"update:visible":function($event){_vm.urlShow=$event}}},[_c('div',[_c('el-form',[_c('el-form-item',{attrs:{"label":""}},[_vm._v(" 可将合同签署链接分享给签署方 ")]),_c('el-form-item',{attrs:{"label":""}},[_c('el-input',{attrs:{"placeholder":"请输入内容","disabled":""},model:{value:(_vm.signUrl),callback:function ($$v) {_vm.signUrl=$$v},expression:"signUrl"}},[_c('template',{slot:"append"},[_c('div',{staticClass:"point",attrs:{"id":"copy-url"},on:{"click":function($event){return _vm.copyUrl()}}},[_vm._v(" 复制链接 ")])])],2)],1)],1)],1)]),_c('el-dialog',{attrs:{"title":"合同文件，点击查看","visible":_vm.docShow,"width":"30%"},on:{"update:visible":function($event){_vm.docShow=$event}}},[_c('div',_vm._l((_vm.docs),function(doc,i){return _c('el-link',{key:i,attrs:{"href":doc.downloadUrl || doc.fileDownloadUrl,"target":"_blank"}},[_vm._v(_vm._s(doc.fileName))])}),1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }