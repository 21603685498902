<template>
  <!-- 签署流程模板 -->
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="发起时间">
        <el-date-picker
          v-model="timeList"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :clearable="false"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-form-item>
    </el-form>

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #createTime="{ row }">
        <div>
          {{ new Date(row.signFlowStartTime).Format("yyyy-MM-dd hh:mm:ss") }}
        </div>
      </template>
      <template #status="{ row }">
        <div>
          {{ statusMap[row.signFlowStatus] }}
        </div>
      </template>
       <template #signers="{ row }">
        <div>
            <div v-for="(sign,i) in row.signers" :key="i" >
                <p v-if="sign.psnSigner">个人：{{sign.psnSigner.psnAccount.accountMobile}}</p>
                 <p v-if="sign.orgSigner">企业：{{sign.orgSigner.orgName}}</p>
            </div>
        </div>
      </template>

      <template #handler="{ row }">
        <div>
             <!-- <el-button size="small" type="primary" @click="toOrgSign(row)"
            >签署</el-button
          > -->
          <el-button size="small" type="primary" @click="getUserUrl(row)"
            >签署链接</el-button
          >
          <el-button size="small" type="success" @click="getfileUrl(row)"
            >查看合同</el-button
          >
        </div>
      </template>
    </auto-table>

    <el-dialog title="用户签署链接" :visible.sync="urlShow" width="30%">
      <div>
        <el-form>
          <el-form-item label=""> 可将合同签署链接分享给签署方 </el-form-item>
          <el-form-item label="">
            <el-input placeholder="请输入内容" disabled v-model="signUrl">
              <template slot="append">
                <div class="point" id="copy-url" @click="copyUrl()">
                  复制链接
                </div>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog title="合同文件，点击查看" :visible.sync="docShow" width="30%">
      <div>
        <el-link
          v-for="(doc, i) in docs"
          :key="i"
          :href="doc.downloadUrl || doc.fileDownloadUrl"
          target="_blank"
          >{{ doc.fileName }}</el-link
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      DataList: [],
      Option: [
        { name: "签署名称", value: "signFlowTitle" },
        { name: "签约方", value: "signers", type: "custom" },
        { name: "状态", value: "status", type: "custom" },
        { name: "发起时间", value: "createTime", type: "custom" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      type: "",

      statusMap: {
        0: "草稿",
        1: "签署中",
        2: "完成",
        3: "撤销",
        5: "过期",
        7: "拒签",
      },
      timeList: [],
      urlShow: false,
      signUrl: "",
      docShow: false,
      docs: [],
    };
  },

  mounted() {
   const end = new Date().format('yyyy-MM-dd');
    const start = new Date(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 90)).format('yyyy-MM-dd');
    this.timeList = [start, end];
  },
  methods: {
    toOrgSign(row){
          let data = {
        signFlowId: row.signFlowId,
        psnId :"93795f2ad2244f449d6168151e18be35",
        orgId:'2c4ed4c5690c476d9ebfde1efa034ca1'
      };
      
      this.$post("user/esign/signUrl", data).then((res) => {
        
      });
    },
    getfileUrl(row) {
      if (row.signFlowStatus == 2) {
        this.$get("user/esign/fileDownloadUrl", { id: row.signFlowId }).then(
          (res) => {
            this.docs = res.data.data.files;
            this.docShow = true;
          }
        );
      } else {
        this.$get("user/esign/querySignFlowDetails", {
          id: row.signFlowId,
        }).then((res) => {
          this.$get("user/esign/previewFileDownloadUrl", {
            signFlowId: row.signFlowId,
            fileId: res.data.data.docs[0].fileId,
          }).then((v) => {
            this.docs = [v.data.data];
            this.docShow = true;
          });
        });
      }
    },
    async copyUrl() {
      await navigator.clipboard.writeText(this.signUrl);
      this.$message("复制成功");
    },
    //获取签约链接
    getUserUrl(row) {
      let data = {
        signFlowId: row.signFlowId,
      };
    //   let signer = row.signers[0];
    //   if (signer.psnSigner) {
    //     data.psnAccount = signer.psnSigner.psnAccount.accountMobile;
    //   }

      this.$post("user/esign/signUrl", data).then((res) => {
        this.signUrl = res.data.data.shortUrl;
        this.urlShow = true;
      });
    },
    load() {
      this.getList(1);
    },
    //列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/esign/querySignFlowList",
        params: {
          currentPage: page,
          pageSize: 10,
          signFlowStartTimeFrom: new Date(this.timeList[0]+' 00:00:00').getTime(),
          signFlowStartTimeTo: new Date(this.timeList[1]+' 23:59:59').getTime(),
        },
      }).then((res) => {
        this.DataList = res.data.data.signFlowInfos;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>