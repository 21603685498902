<template>
  <!-- 签署流程模板 -->
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="发起时间">
        <el-date-picker
          v-model="timeList"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :clearable="false"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-form-item>
    </el-form>

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #createTime="{ row }">
        <div>
            <div>
                 发起时间{{ new Date(row.draftStartTime).Format("yyyy-MM-dd hh:mm:ss") }}
            </div>
           <div v-if="row.draftFinishTime">
                 操作时间{{ new Date(row.draftFinishTime).Format("yyyy-MM-dd hh:mm:ss") }}
            </div>
        </div>
      </template>
       <template #signers="{ row }">
        <div>
            <div v-for="(sign,i) in row.drafters" :key="i" >
                <p v-if="sign.psnDrafter">个人：{{sign.psnDrafter.psnAccount.accountMobile}}</p>
                 <p v-if="sign.orgDrafter">企业：{{sign.orgDrafter.orgName}}</p>
            </div>
        </div>
      </template>
      <template #status="{ row }">
        <div>
          合同拟定：{{ statusMap[row.draftStatus] }}
        </div>
        <div>
          合同填写：{{ fillStatus[row.drafters[0].fillStatus] }}
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button size="small" type="primary" @click="getUserUrl(row)"
            >签署链接</el-button
          >
          <el-button size="small" type="success" @click="getFileUrl(row)"
            >查看文件</el-button
          >
          <el-button
            v-if="row.drafters[0].fillStatus == 1"
            size="small"
            type="danger"
            @click="cancelFlow(row)"
            >撤销合同拟定</el-button
          >
        </div>
      </template>
    </auto-table>
    <el-dialog title="用户签署链接" :visible.sync="urlShow" width="30%">
      <div>
        <el-form>
          <el-form-item label=""> 可将合同签署链接分享给签署方 </el-form-item>
          <el-form-item label="">
            <el-input placeholder="请输入内容" disabled v-model="signUrl">
              <template slot="append">
                <div class="point" id="copy-url" @click="copyUrl()">
                  复制链接
                </div>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog title="合同文件，点击查看" :visible.sync="docShow" width="30%">
      <div>
        <el-link
          v-for="(doc, i) in docs"
          :key="i"
          :href="doc.downloadUrl"
          target="_blank"
          >{{ doc.downloadUrl ? doc.fileName : "合同拟定后可查看" }}</el-link
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      DataList: [],
      Option: [
        { name: "签署名称", value: "signFlowTitle" },
        { name: "签约方", value: "signers", type: "custom" },
        { name: "状态", value: "status", type: "custom" },
        { name: "发起时间", value: "createTime", type: "custom" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      type: "",

      statusMap: {
        1: "拟定中",
        2: "完成",
        3: "撤销",
        4: "拒填",
        5: "过期",
      },
      fillStatus: {
        1: "未填写",
        2: "填写中",
        3: "已填写",
        4: "拒填",
      },
      timeList: [],
      urlShow: false,
      signUrl: "",

      docShow: false,
      docs: [],
    };
  },

  mounted() {
    const end = new Date().format('yyyy-MM-dd');
    const start = new Date(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 90)).format('yyyy-MM-dd');
    this.timeList = [start, end];
  },
  methods: {
    getFileUrl(row) {
      this.$get("user/esign/getSignFlowDraftDetails", {
        signFlowId: row.signFlowId,
      }).then((res) => {
        let draft = res.data.data;
        if (draft.docs.length > 0) {
          this.docShow = true;
          this.docs = draft.docs;
        } else {
          this.$message("合同未拟定");
          this.docs = [];
        }
      });
    },

    cancelFlow(row) {
      this.$confirm("确定撤销合同拟定？").then((res) => {
        this.$post("/user/esign/cancelFlow", {
          signFlowId: row.signFlowId,
        }).then((res) => {
          this.getList(1);
        });
      });
    },

    async copyUrl() {
      await navigator.clipboard.writeText(this.signUrl);
      this.$message("复制成功");
    },
    //获取签约链接
    getUserUrl(row) {
      let data = {
        signFlowId: row.signFlowId,
      };
      let signer = row.drafters[0];
      if (signer.psnDrafter) {
        data.psnAccount = signer.psnDrafter.psnAccount.accountMobile;
      }

      this.$post("user/esignTemp/getDraftUrl", data).then((res) => {
        this.signUrl = res.data.data.draftShortUrl;
        this.urlShow = true;
      });
    },
    load() {
      this.getList(1);
    },
    //列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/esign/querySignFlowByTempList",
        params: {
          currentPage: page,
          pageSize: 10,
          signFlowStartTimeFrom: new Date(this.timeList[0]+' 00:00:00').getTime(),
          signFlowStartTimeTo: new Date(this.timeList[1]+' 23:59:59').getTime(),
        },
      }).then((res) => {
        this.DataList = res.data.data.draftInfos;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>