<template>
  <div class="container">
      <el-alert
      v-if="activeName == 'draft'"
    title="用户通过链接填写信息完成合同拟定后，系统会自动发起合同签署流程"
    type="info">
  </el-alert>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="合同签署" name="flow">
        <signFlow ref="flow"></signFlow>
      </el-tab-pane>
        <el-tab-pane label="合同拟定" name="draft">
        <signDraft ref="draft"></signDraft>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import signDraft from '../components/signDraft.vue';
import signFlow from '../components/signFlow.vue';

export default {
  components: {
    signDraft,
    signFlow
  },
  data() {
    return {
      activeName: "flow",
    };
  },

  mounted() {
    this.$nextTick(()=>{
      this.$refs.flow.load()
    })
  },

  methods: {
    handleClick(tab) {
      this.$refs[tab.name].load();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>